import config from "./config.json"

interface PriceIds {
    annual: string
    monthly: string
}

interface SubscriptionConfig {
    product_id: string
    price_ids: PriceIds
}

interface CertificateConfig {
    product_id: string
    account_type: {
        private: {
            price_id: string
        }
        enterprise: {
            price_id: string
        }
    }
}

interface EnvironmentConfig {
    consumption_certificate: CertificateConfig
    demand_certificate: CertificateConfig
    pro_subscription: SubscriptionConfig
    s_subscription: SubscriptionConfig
    m_subscription: SubscriptionConfig
    l_subscription: SubscriptionConfig
}

interface StripeConfig {
    production: EnvironmentConfig
    development: EnvironmentConfig
}

const environment = process.env.STRIPE_MODE === "live" ? "production" : "development"

const stripeConfig: EnvironmentConfig = (config as StripeConfig)[environment]
export default stripeConfig
