import { z } from "zod"
import { Ref } from "../common"
import { SubscriptionSchema } from "../subscription"

//TODO: Bad design choice that we got ProductId and ProductIdentifier which are kinda the same but ProductIdentifier is more specific by including the project and variant -> refactor
enum ProductId {
    ConsumptionCertificate = 1, // Verbrauchsausweis
    DemandCertificate = 2, //Bedarfsausweis
    ProSubscription = 3,
    S_Subscription = 4,
    M_Subscription = 5,
    L_Subscription = 6,
}

const ProductIdSchema = z.nativeEnum(ProductId)

enum ProductType {
    Subscription = "subscription",
    Certificate = "certificate",
}

const ProductSchema = z.object({
    id: z.number(),
    name: z.string(),
    price: z.number().positive().nullable(), // This can be null for subscriptions which define their own prices
    productType: z.enum([ProductType.Certificate, ProductType.Subscription]),
    subscriptionRef: Ref(SubscriptionSchema),
    stripeProductId: z.string().nullable(),
})

type IProduct = z.infer<typeof ProductSchema>

export { ProductSchema, ProductId, ProductIdSchema, ProductType }
export type { IProduct }
