import { ACCOUNT_TYPES, AccountTypeId } from "../account-type/constants"
import { ProductId } from "../product"
import stripeConfig from "../stripe/config"
import { EnterpriseSubscriptionId, PrivateSubscriptionId, SubscriptionInterval } from "../subscription"

export function SubscriptionIdToProductId(subscriptionId: PrivateSubscriptionId | EnterpriseSubscriptionId) {
    switch (subscriptionId) {
        case PrivateSubscriptionId.Free:
            return undefined
        case PrivateSubscriptionId.Pro:
            return ProductId.ProSubscription
        case EnterpriseSubscriptionId.S:
            return ProductId.S_Subscription
        case EnterpriseSubscriptionId.M:
            return ProductId.M_Subscription
        case EnterpriseSubscriptionId.L:
            return ProductId.L_Subscription
        default:
            throw new Error(`SubscriptionIdToProductId: ${subscriptionId} is not a Product`)
    }
}

export function ProductIdToStripeProductId(productId: ProductId) {
    switch (productId) {
        case ProductId.ProSubscription:
            return stripeConfig.pro_subscription.product_id
        case ProductId.S_Subscription:
            return stripeConfig.s_subscription.product_id
        case ProductId.M_Subscription:
            return stripeConfig.m_subscription.product_id
        case ProductId.L_Subscription:
            return stripeConfig.l_subscription.product_id
        case ProductId.ConsumptionCertificate:
            return stripeConfig.consumption_certificate.product_id
        case ProductId.DemandCertificate:
            return stripeConfig.demand_certificate.product_id
        default:
            throw new Error(`ProductIdToStripeProductId: ${productId} is not a Stripe`)
    }
}
export function ProductIdToSubscriptionId(productId: ProductId) {
    switch (productId) {
        case ProductId.ProSubscription:
            return PrivateSubscriptionId.Pro
        case ProductId.S_Subscription:
            return EnterpriseSubscriptionId.S
        case ProductId.M_Subscription:
            return EnterpriseSubscriptionId.M
        case ProductId.L_Subscription:
            return EnterpriseSubscriptionId.L
        default:
            throw new Error(`ProductIdToSubscriptionId: ${productId} is not a Subscription Product`)
    }
}

export function StripeProductIdToPriceId(
    args: { productId: string; interval: SubscriptionInterval } | { productId: string; accountTypeId: AccountTypeId }
): string {
    if ("interval" in args) {
        const { productId, interval } = args
        switch (productId) {
            case stripeConfig.pro_subscription.product_id:
                if (!interval) {
                    throw new Error("Interval is required for ProSubscription")
                }
                return interval === SubscriptionInterval.Monthly
                    ? stripeConfig.pro_subscription.price_ids.monthly
                    : stripeConfig.pro_subscription.price_ids.annual
            case stripeConfig.s_subscription.product_id:
                if (!interval) {
                    throw new Error("Interval is required for S_Subscription")
                }
                return interval === SubscriptionInterval.Monthly
                    ? stripeConfig.s_subscription.price_ids.monthly
                    : stripeConfig.s_subscription.price_ids.annual
            case stripeConfig.m_subscription.product_id:
                if (!interval) {
                    throw new Error("Interval is required for M_Subscription")
                }
                return interval === SubscriptionInterval.Monthly
                    ? stripeConfig.m_subscription.price_ids.monthly
                    : stripeConfig.m_subscription.price_ids.annual
            case stripeConfig.l_subscription.product_id:
                if (!interval) {
                    throw new Error("Interval is required for L_Subscription")
                }
                return interval === SubscriptionInterval.Monthly
                    ? stripeConfig.l_subscription.price_ids.monthly
                    : stripeConfig.l_subscription.price_ids.annual
            default:
                throw new Error(`StripeProductIdToPriceId: ${productId} is not a valid Stripe product`)
        }
    } else {
        switch (args.productId) {
            case stripeConfig.consumption_certificate.product_id:
                if (args.accountTypeId === ACCOUNT_TYPES.Enterprise.id) {
                    return stripeConfig.consumption_certificate.account_type.enterprise.price_id
                } else if (args.accountTypeId === ACCOUNT_TYPES.Private.id) {
                    return stripeConfig.consumption_certificate.account_type.private.price_id
                } else {
                    throw new Error(`StripeProductIdToPriceId: ${args.productId} is not a valid Stripe product`)
                }
            case stripeConfig.demand_certificate.product_id:
                if (args.accountTypeId === ACCOUNT_TYPES.Enterprise.id) {
                    return stripeConfig.demand_certificate.account_type.enterprise.price_id
                } else if (args.accountTypeId === ACCOUNT_TYPES.Private.id) {
                    return stripeConfig.demand_certificate.account_type.private.price_id
                } else {
                    throw new Error(`StripeProductIdToPriceId: ${args.productId} is not a valid Stripe product`)
                }

            default:
                throw new Error(`StripeProductIdToPriceId: ${args.productId} is not a valid Stripe product`)
        }
    }
}

const StripePriceIdToSubscriptionIdMap: { [key: string]: PrivateSubscriptionId | EnterpriseSubscriptionId } = {
    [stripeConfig.pro_subscription.price_ids.monthly]: PrivateSubscriptionId.Pro,
    [stripeConfig.pro_subscription.price_ids.annual]: PrivateSubscriptionId.Pro,

    [stripeConfig.s_subscription.price_ids.monthly]: EnterpriseSubscriptionId.S,
    [stripeConfig.s_subscription.price_ids.annual]: EnterpriseSubscriptionId.S,

    [stripeConfig.m_subscription.price_ids.monthly]: EnterpriseSubscriptionId.M,
    [stripeConfig.m_subscription.price_ids.annual]: EnterpriseSubscriptionId.M,

    [stripeConfig.l_subscription.price_ids.monthly]: EnterpriseSubscriptionId.L,
    [stripeConfig.l_subscription.price_ids.annual]: EnterpriseSubscriptionId.L,
}

export function StripePriceIdToSubscriptionId(stripePriceId: string): PrivateSubscriptionId | EnterpriseSubscriptionId {
    const subscriptionId = StripePriceIdToSubscriptionIdMap[stripePriceId]
    if (!subscriptionId) {
        throw new Error(`No subscription mapping found for Stripe Price ID: ${stripePriceId}`)
    }
    return subscriptionId
}

export function StripeProductIdToProductId(stripeProductId: string) {
    switch (stripeProductId) {
        case stripeConfig.pro_subscription.product_id:
            return ProductId.ProSubscription
        case stripeConfig.s_subscription.product_id:
            return ProductId.S_Subscription
        case stripeConfig.m_subscription.product_id:
            return ProductId.M_Subscription
        case stripeConfig.l_subscription.product_id:
            return ProductId.L_Subscription
        case stripeConfig.consumption_certificate.product_id:
            return ProductId.ConsumptionCertificate
        case stripeConfig.demand_certificate.product_id:
            return ProductId.DemandCertificate
    }
}

export const isFreeSubscription = (subscriptionId: PrivateSubscriptionId | EnterpriseSubscriptionId) =>
    subscriptionId === PrivateSubscriptionId.Free || subscriptionId === EnterpriseSubscriptionId.Free

export function isCertificateProduct(productId: ProductId) {
    return productId === ProductId.ConsumptionCertificate || productId === ProductId.DemandCertificate
}
export function isSubscriptionProduct(productId: ProductId) {
    return !isCertificateProduct(productId)
}
