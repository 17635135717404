import { handleFetch, apiUrl, baseUrl } from "@api/apiConfig"
import { useAppState } from "@hooks/useState"
import { AuthResponseSchema } from "./schema"

export type IConfirmation = {
    queryString: string
}

export async function confirmation(params: IConfirmation) {
    const { queryString } = params
    const response = await handleFetch(async () =>
        fetch(`${apiUrl}/auth/email-confirmation${queryString}`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
            },
        })
    )

    const parsedAuthResponse = AuthResponseSchema.parse({
        ...response,
        user: {
            ...response.user,
            avatar: {
                ...response.user.avatar,
                url: response.user.avatar?.url ? `${baseUrl}${response.user.avatar?.url}` : "",
            },
        },
    })

    useAppState.getState().setAuthentication(parsedAuthResponse.jwt, parsedAuthResponse.user)

    return parsedAuthResponse
}
